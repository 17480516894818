import {
  ProductItem,
  RecipeIngredient,
  Retailer,
} from "~/features/Recipes/types";
import { Lang } from "~/types";
import ProductListItem from "./ProductListItem";
import { getIngProducts } from "../utils";
import { RetailerPreviewFragment } from "~/features/Retailers/fragments.generated";
import { useGetRetailers } from "~/features/Retailers/hooks/useGetRetailers";
import { Loading } from "~/layouts";

type Props = {
  recipeIngredient: RecipeIngredient;
  lang: Lang;
  showMoreId?: string;
  index: number;
  showAllProducts: boolean;
};

const DropDownProductsList = ({
  recipeIngredient,
  lang,
  showMoreId,
  index,
  showAllProducts,
}: Props) => {
  const { ingredient } = recipeIngredient;
  const { data, error, loading } = useGetRetailers(1, 1000);

  if (loading) <Loading />;
  if (error) throw error;

  const retailers = data?.retailers.items ?? [];

  const productList = getIngProducts(ingredient.products, retailers);
  const showMore = showMoreId === index.toString() || showAllProducts;

  return (
    <div className={`more-info ${showMore ? "extended" : ""}`}>
      <ul className={showMore ? "flex flex-col pt-3 pb-2 pl-8" : "pl-8"}>
        {productList?.map((productItem: ProductItem) => (
          <ProductListItem
            key={productItem.id}
            productItem={productItem}
            lang={lang}
          />
        ))}
      </ul>
    </div>
  );
};

export default DropDownProductsList;
