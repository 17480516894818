import { DropResult } from "react-beautiful-dnd";
import {
  Ingredient,
  NutritionInfo,
  RecIngBrand,
  RecipeIngredient,
  RecipeIngredientIng,
  RecipeProduct,
  Retailer,
} from "../../types";
import { Lang, MeasurmentOption, Option } from "~/types";
import { IngredientModalValues } from "./types";
import _ from "lodash";
import { FetcherWithComponents } from "@remix-run/react";
import { RetailerPreviewFragment } from "~/features/Retailers/fragments.generated";

export const getStateValues = (ids: string[] = []) => {
  const searchParams = new URLSearchParams();
  ids.forEach((id) => {
    searchParams.append("id", id);
  });
  return searchParams;
};

export const loadNutritions = (
  defaultIngredient: Ingredient,
  nutritionFetcher: FetcherWithComponents<any>
) => {
  const route = "/resources/recipes/ingredients";
  const ids = getStateValues(defaultIngredient.nutritionInformationIds);
  const nutritionEndpoint = `${route}?${ids}`;
  nutritionFetcher.load(nutritionEndpoint);
};

export const getOnlyFoodIngs = (allIngredients: Ingredient[], lang: Lang) =>
  allIngredients
    .filter((ing) => ing.typ !== "norecipe")
    .map((ing) => ({ ...ing, value: ing.title?.singular?.[lang] ?? "n/a" }))
    .sort((a, b) => a.value.localeCompare(b.value));

export const createNutritionOptions = (
  values: NutritionInfo[] = [],
  lang: Lang
): Option[] =>
  values.map((item) => ({
    id: item.id,
    value: item.title?.[lang] ?? "title missing",
  }));

export const getDefaultIngredient = (
  foodOnlyIngredients: Ingredient[],
  ingredient?: RecipeIngredientIng
): Ingredient | undefined =>
  foodOnlyIngredients.find((ing) => ing.id === ingredient?.id);

export const getDefaultBrand = (
  brandOptions: Option[],
  item?: RecipeIngredient
): Option | undefined =>
  brandOptions?.find((opt) => opt.id === item?.ingredientBrandId);

export const getIngName = (
  ingredients: Ingredient[],
  lang: Lang,
  defaultIngredient?: Ingredient
) => {
  const match = ingredients.find(({ id }) => id === defaultIngredient?.id);
  return match?.title.singular?.[lang] ?? "";
};

export const getFilteredIngs = (
  recIngs: RecipeIngredient[],
  options: FoodOnlyOption[]
): FoodOnlyOption[] => {
  const comparator = (option: Option, recIng: RecipeIngredient) =>
    recIng.ingredient.id === option.id;
  return _.differenceWith(options, recIngs, comparator);
};

export const findState = (options: Option[], nutritionInformationId?: string) =>
  options?.find((opt) => opt.id === nutritionInformationId);

export const getSelectedMetric = (
  metric: MeasurmentOption[],
  item?: RecipeIngredient
): MeasurmentOption | undefined =>
  metric?.find((m) => m.id === item?.metricMeasure?.id);

export const getSelectedImperial = (
  imperial: MeasurmentOption[],
  item?: RecipeIngredient
): MeasurmentOption | undefined =>
  imperial?.find((m) => m.id === item?.imperialMeasure?.id);

export const getSelectedProductImperial = (
  productImperial: MeasurmentOption[],
  item?: RecipeIngredient
): MeasurmentOption | undefined =>
  productImperial?.find((m) => m.id === item?.imperialProductMeasure?.id);

export const getIngredientMeasure = (
  metric: Option[],
  measurementId?: string
): string | undefined => metric?.find((m) => m.id === measurementId)?.value;

export const buildBrandOptions = (brands: RecIngBrand[] = []): Option[] =>
  brands.map((brand: RecIngBrand) => ({
    id: brand.id,
    value: brand.name,
  }));

export const getUpdatedItems = (
  result: DropResult,
  items: RecipeIngredient[],
  isDisabled: boolean
): RecipeIngredient[] => {
  if (!result.destination || isDisabled) return items;
  return reorder(items, result.source.index, result.destination.index);
};

const reorder = (
  list: RecipeIngredient[],
  startIndex: number,
  endIndex: number
): RecipeIngredient[] => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

export const getFormValues = (
  defaultIngredient: Ingredient,
  currentItem: RecipeIngredient,
  recipeId: string,
  lang: Lang,
  ingredientMeasure?: string,
  selectedMetric?: MeasurmentOption,
  selectedImperial?: MeasurmentOption,
  selectedProductImperial?: MeasurmentOption
): IngredientModalValues => ({
  ingredientName: defaultIngredient?.title?.singular?.[lang] ?? undefined,
  ingredient: defaultIngredient?.id,
  ingredientBrandId: currentItem?.ingredientBrandId,
  changeable: currentItem?.changeable,
  canBeRemoved: currentItem?.canBeRemoved,
  metricQuantity:
    currentItem?.metricQuantity && cleanNumber(currentItem.metricQuantity),
  metricMeasure: selectedMetric?.id,
  nutritionInformationId: currentItem?.nutritionInformationId,
  imperialQuantity:
    currentItem?.imperialQuantity && cleanNumber(currentItem.imperialQuantity),
  imperialMeasure: selectedImperial?.id,
  imperialProductQuantity: currentItem?.imperialProductQuantity,
  imperialProductMeasure: selectedProductImperial?.id,
  metricProductQuantity:
    currentItem?.metricProductQuantity &&
    cleanNumber(currentItem.metricProductQuantity),
  metricProductQuantityOverride:
    currentItem?.metricProductQuantityOverride &&
    cleanNumber(currentItem.metricProductQuantityOverride),
  useGramsForMetricProductQuantityOverride:
    currentItem?.useGramsForMetricProductQuantityOverride
      ? "g"
      : ingredientMeasure,
  recipeId,
});

export type FoodOnlyOption = Option & { typ: string };

export const buildFoodOnlyOptions = (
  ingredients: Ingredient[],
  lang: Lang
): FoodOnlyOption[] =>
  ingredients.map((ing) => ({
    id: ing.id,
    typ: ing.typ,
    value: ing.title.singular?.[lang] ?? "",
  }));

export const getIngProducts = (
  products: RecipeProduct[] = [],
  retailers: RetailerPreviewFragment[]
) =>
  products
    .filter((prod: RecipeProduct) => prod.retailer)
    .map((product: RecipeProduct) => {
      const retailer = retailers.find((r) => product.retailer === r.id);
      return {
        ...product,
        retailerName: retailer?.name,
      };
    });

export const cleanNumber = (num: number): number => {
  const tolerance = 1e-6;
  const rounded = Math.round(num);

  if (Math.abs(num - rounded) < tolerance) {
    return rounded;
  }

  return parseFloat(num.toFixed(5));
};
